:root {
  font-size: 1.25em;
  font-family: 'Open Sans';
  --blue-color: #002B4D;
  --light-blue-color: #335571;
  --gray-text: #202326;
}

html, body {
  width: 100% !important;
}

h2 {
  color: var(--light-blue-color);
  text-transform: uppercase;
}

h3 {
  color: var(--light-blue-color);
}

.gray {
  color: var(--gray-text);
}

header {
  height: 100vh;
  background-image: url("/images/pexels-tranmautritam-326501.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

#home-nav {
  background: rgba(255,255,255,0.5);
  padding: 0.5rem 0;
}

nav {
  padding-top: 1rem;
  text-align: right;
}

@media only screen and (max-width: 425px){
	#home-nav {
    text-align: center !important;
  }

  nav {
    text-align: center;
  }
}

nav .btn {
  color: var(--blue-color);
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-left: 3rem;
  font-weight: 600;
  text-transform: uppercase;
}

#logo {
  max-height: 80px;
}

#home-header {
  min-height: 400px;
  margin: 2rem 0;
}

#home-header .text-content {
  min-height: 300px;
}

section {
  margin: 3rem 0;
}

footer {
  background: #002B4D;
  color: #fff;
  padding: 2rem 0;
}

footer .footer-nav .btn {
  color: #fff;
  display: block;
  padding: 0;
  margin: 0;
}